.Application {
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-Header {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: black;
  margin: 0px;
  height: 5vh;
}

.App-link {
  color: #61dafb;
}

.Header-Link {
  font-size: 1.2em;
  color: white;
  margin-left: 20px;
  display: inline;
    text-decoration:  none;
}

.Header-Link:hover {
  color: steelblue;
}

.Header-Link-Active {
  color: goldenrod;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
