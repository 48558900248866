.Problem-Outter {
}

.Problem-Left {
  height: 100%;
  overflow-y: scroll;
}

.Problem-Left-Title {
  padding-left: 20px;
  padding-right: 20px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  margin-bottom: 40px;
}

.Problem-Right {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.Code-Input {
  border-color: black;
  border-width: 2px;
  border-style: solid;
  display: flex;
  flex: 1;
  margin: 10px;
}

.Difficulty-Easy {
  color: green;
}

.Difficulty-Medium {
  color: orange;
}

.Difficulty-Hard {
  color: red;
}

.Problem-Title {
  margin-bottom: 0px;
}

.Problem-Summary {
  margin-top: 0px;
}

.Problem-Left-Window {
  display: block;
  width: 100%;
}

.Problem-Left-Window-Header {
  background-color: black;
  padding: 10px;
  padding-left: 20px;
  border-bottom-color: grey;
  border-bottom-style: solid;
  border-bottom-width: 2px;
}

.Problem-Left-Window-Header:hover {
  cursor: pointer;
}

.Problem-Left-Window-Header > h2 {
  color: white;
}

.Problem-Left-Window-Header-With-Button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Problem-Left-Window-Header-With-Button > div {
  display: flex;
  background-color: green;
  justify-content: center;
  min-width: 50px;
  padding-left: 30px;
  padding-right: 30px;
  align-items: center;
  border-radius: 5px;
  color: white;
}

.Problem-Left-Window-Header-With-Button > div:hover {
  background-color: lightgreen;
  cursor: pointer;
}

.Problem-Left-Window-Content-Hidden {
  max-height: 0;
  -webkit-transition: max-height 0.25s ease-out;
  -moz-transition: max-height 0.25s ease-out;
  -o-transition: max-height 0.25s ease-out;
  -ms-transition: max-height 0.25s ease-out;
  transition: max-height 0.25s ease-out;
}

.Problem-Left-Window-Content {
  margin: 0px;
  padding: 15px;
  max-height: 800px;
  overflow-y: auto;
  -webkit-transition: max-height 0.25s ease-in;
  -moz-transition: max-height 0.25s ease-in;
  -o-transition: max-height 0.25s ease-in;
  -ms-transition: max-height 0.25s ease-in;
  transition: max-height 0.25s ease-in;
}

.Problem-Left-Test {
  width: 100%;
}

.Problem-Left-Test > h3 {
  margin-bottom: 0px;
}

.Problem-Left-Test-Ace-Editor {
  margin-top: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: black;
}

.ace_hidden-cursors {
  opacity:0
} 

.Problem-Loading {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Problem-Loading > img {
  height: 150px;
}