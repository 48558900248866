.Questions-Page {
	height: 100%;
	margin: 0px;
	padding: 0px;
}

.Questions-Page > h1 {
	margin: 0px;
}

.QuestionBox {
    margin-left: 10vw;
    margin-right: 10vw;
    margin-top: 1vh;
    border-style: outset;
    border-color: grey;
    border-width: thick;
    display: flex;
    flex-direction: row;
}

.QuestionBox:hover {
	cursor: pointer;
	background-color: steelblue;
}

.QuestionBox-Title {
	margin-left: 5%;
    vertical-align: middle;
    width: 75%;
    display: flex;
    flex-direction: column;
}

.QuestionBox-Title > h2 {
	margin-bottom: 10px;
    margin-right: 50px;
    overflow: auto;
}

.QuestionBox-Title > p {
	margin-top: 0px;
    margin-right: 50px;
    overflow: auto;
}

.QuestionBox-Difficulty {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
}