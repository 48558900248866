.Unauthorized {
	display: flex;
	padding-top: 20vh;
	justify-content: center;
}

.Unauthorized > div {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 50px;
	border-width: 2px;
	border-color: #E84849;
	border-style: solid;
	border-radius: 5px;
}

.Unauthorized > div > h1 {
	margin-bottom: 0px;
}